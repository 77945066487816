<template>
  <Modal ref="roleModal" v-model="roleModal" class-name="roleStyle" :title="roleTitle" width="1100"
    :transfer="false" :mask-closable="false" @on-visible-change="roleModalChange" :loading="modalLoading">
    <!--头部角色名称-->
    <Form ref="roleForm" :model="roleForm" label-position="right" :rules="ruleCustom" :label-width="75" @submit.native.prevent>
      <Form-item :label="$t('key1004009')" prop="roleName">
        <Input v-model.trim="roleForm.roleName" clearable style="width: 180px" :placeholder="$t('key1004010')"></Input>
      </Form-item>
    </Form>
    <div class="role_box">
      <!--左边的菜单模块-->
      <div class="left_role_box">
        <div class="title_box">
          <h2 class="title">{{ $t('key1004013') }}</h2>
          <Checkbox v-model="modularAllTalg" :indeterminate="indeterminateAllMenu" @on-change="cahngeAllMenuRole"></Checkbox>
        </div>
        <div class="left_item" v-for="(item, index) in moduleList" :key="index" @click="switchMenu(item, index)">
          <span class="item_title" :class="currentIndex === index ? 'item_title_active' : ''">{{ item.name }}</span>
          <Checkbox v-model="item.modularTalg" :indeterminate="item.indeterminateMenu" @on-change="cahngeMenuRoleAll($event, item)"></Checkbox>
        </div>
      </div>
      <!--右边的页面权限模块-->
      <div class="right_role_box">
        <Collapse v-model="collapseValue" v-if="panelList">
          <template v-for="(item, index) in panelList">
            <Panel :key="item.menuKey + '_' + index" :name="item.menuKey">
              <span class="right_role_title">{{ item.name }}</span>
              <Checkbox class="right_checkbox" v-model="item.pageRoleTalg" :indeterminate="item.indeterminatePages"
                @click.prevent.stop.native="changePageRoleAll($event, item)">{{ $t('key1000574') }}
              </Checkbox>
              <template #content>
                <div>
                  <Checkbox-group class="page_role_box" v-model="selectPageRoleList" @on-change="selectRoleList">
                    <template v-for="(talg, index) in item.pageRoleList">
                      <div class="roleItem" :key="index">
                        <Checkbox :label="talg.operId" :title="talg.operName">
                          <span class="page_role_item_title">{{ talg.operName }}</span>
                        </Checkbox>
                      </div>
                      <div class="bigSquare" v-if="talg.childrens && talg.childrens.length > 0 && selectPageRoleList.includes(talg.operId)">
                        <template v-for="(subItem, subIndex) in talg.childrens">
                          <div :key="subIndex" class="roleItem">
                            <Checkbox :label="subItem.operId" :title="subItem.operName">
                              <span class="page_role_item_title">{{ subItem.operName }}</span>
                            </Checkbox>
                          </div>
                        </template>
                      </div>
                    </template>
                  </Checkbox-group>
                </div>
              </template>
            </Panel>
          </template>
        </Collapse>
      </div>
    </div>
    <template #footer>
      <div style="text-align: center;">
        <Button @click="roleModal = false">{{ $t('key1000097') }}</Button>
        <Button type="primary" @click="roleBtn" v-if="(roleType === 'add' && getPermission('ymsSupplierUserInfo_insert'))
      || (roleType === 'edit' && getPermission('ymsSupplierUserInfo_update'))">{{ $t('key1000096') }}</Button>
      </div>
    </template>

  </Modal>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import supplierMenu from "@/config/menu/supplier";
import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';

export default {
  name: "roleModal",
  mixins: [Mixin],
  props: {
    roleType: { // 状态类型
      type: String,
      default: 'add'
    },
    roleName: { // 角色名称
      type: String,
      default: ''
    }
  },
  data() {
    return {
      roleModal: false,
      roleTitle: alias2f085dd487c1465383484787b29fef2c.t('key1004014'),
      roleForm: {
        roleName: ''
      },
      modalLoading: false,
      roleId: null,
      ruleCustom: {
        roleName: [
          {
            required: true,
            message: alias2f085dd487c1465383484787b29fef2c.t('key1004015'),
            trigger: 'blur'
          }
        ]
      },
      moduleList: [],
      panelList: [],
      roleAllList: [],
      currentIndex: 0,
      collapseValue: [],
      selectPageRoleList: [],
      topNavActiveName: localStorage.getItem('topNavActiveName') || 'operationManage', // 默认选中的顶部导航菜单
      modularAllTalg: false,
      indeterminateAllMenu: null,
      originalIndex: 0
    };
  },
  methods: {
    // 获取角色的详情数据
    getRoleData(roleId) {
      let v = this;
      v.roleId = roleId;
      v.roleAllList = [];
      v.selectPageRoleList = [];
      v.axios.get(api.get_role + roleId + '?subsystemCode=yms-supplier-service', {loading: true}).then(response => {
        if (response.data.code === 0) {
          v.roleModal = true;
          let data = response.data.datas;
          let new_arr = [];
          let roleArr = [];
          if (data) {
            function roleLists(rolelist) {
              rolelist.map((item) => {
                if (item.childrens && item.childrens.length > 0) {
                  roleLists(item.childrens);
                  roleArr.push(item);
                } else {
                  roleArr.push(item);
                }
              });
            }

            roleLists(data);
            if (roleArr) {
              roleArr.map((item) => {
                if (item.checked) {
                  v.selectPageRoleList.push(item.operId);
                }
              });
              v.originalSelectRoleList = JSON.parse(JSON.stringify(v.selectPageRoleList));
            }
            new_arr = v.handerGrouping(data, function (item) {
              return [item.menuId];
            }, 'menuId');
            v.roleAllList = new_arr;
            v.initializationMenu(roleArr);
          }
        }
      });
    },
    // 初始化处理左边模块菜单是否被选中的状态
    initializationMenu(data) {
      let v = this;
      // 过滤掉不需要权限控制的菜单
      let newList = [];
      for (let key in supplierMenu) {
        newList.push(...supplierMenu[key])
      }
      let menuList = newList.filter(ele => !ele.jurisdictionHide);
      v.moduleList = []; // 左边菜单模块的数据
      if (menuList) {
        menuList.map((item, index) => {
          v.moduleList.push({
            ...item,
            modularTalg: false,
            currentIndex: index,
            indeterminateMenu: false,
            selectOperIdList: [],
            operIdList: []
          });
        });
      }
      if (data) {
        data.map((talg) => {
          if (v.moduleList) {
            v.moduleList.map((ele) => {
              let menuData = [];
              if (ele.menuKey) {
                if (ele.menuKey === talg.menuId) {
                  ele.operIdList.push(talg.operId);
                  if (talg.checked) {
                    ele.selectOperIdList.push(talg.operId);
                  }
                }
              } else {
                if (ele.children && ele.children.length > 0) {
                  function handerMenu(menu) {
                    menu.forEach((item) => {
                      if (item.children && item.children.length > 0) {
                        handerMenu(item.children);
                        menuData.push(item);
                      } else {
                        menuData.push(item);
                      }
                    });
                  }

                  handerMenu(ele.children);
                  if (menuData.length > 0) {
                    menuData.map((obj) => {
                      if (obj.menuKey) {
                        if (obj.menuKey === talg.menuId) {
                          ele.operIdList.push(talg.operId);
                          if (talg.checked) {
                            ele.selectOperIdList.push(talg.operId);
                          }
                        }
                      }
                    })
                  }
                }
              }
              if (ele.selectOperIdList.length > 0) {
                if (ele.operIdList.length === ele.selectOperIdList.length) {
                  ele.modularTalg = true;
                  ele.indeterminateMenu = false;
                } else {
                  ele.modularTalg = false;
                  ele.indeterminateMenu = true;
                }
              } else {
                ele.modularTalg = false;
                ele.indeterminateMenu = null;
              }
            });
          }
        });
        if (v.moduleList) {
          v.handerRightMenu(v.moduleList[0]);
        }
      }
    },
    // 新增、编辑角色的确认按钮
    roleBtn() {
      let v = this;
      let url = '';
      let text = '';
      let method = '';
      let data = '';
      // 新增角色
      if (v.roleType === 'add') {
        text = alias2f085dd487c1465383484787b29fef2c.t('key1000578');
        method = 'POST';
        url = api.post_role;
        data = {
          operIdList: v.selectPageRoleList,
          roleName: v.roleForm.roleName
        };
      }
      // 编辑角色
      else if (v.roleType === 'edit') {
        text = alias2f085dd487c1465383484787b29fef2c.t('key1000868');
        method = 'PUT';
        url = api.put_role;
        data = {
          operIdList: v.selectPageRoleList,
          roleId: v.roleId,
          roleName: v.roleForm.roleName
        };
      }
      v.modalLoading = true;
      let query = {
        loading: true,
        loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212'),
        method: method,
        url: url,
        data: data
      };
      v.$refs.roleForm.validate((valid) => {
        if (valid) {
          v.axios(query).then(response => {
            v.modalLoading = false;
            if (response.data.code === 0) {
              v.roleModal = false;
              v.$Message.success(text);
              v.$emit('saveSuccess');
            }
          });
        }
      });
    },
    // 监听角色弹窗--重置数据
    roleModalChange(value) {
      let v = this;
      if (value) {
        v.roleForm.roleName = v.roleName;
      } else {
        v.$refs['roleForm'].resetFields();
        v.selectRoleAll = {};
        v.selectPageRoleList = [];
        v.moduleList = [];
        v.panelList = [];
        v.currentIndex = 0;
      }
    },
    // 切换左边菜单模块
    switchMenu(item, index, type) {
      this.currentIndex = index;
      if (type !== 'all') {
        this.originalIndex = index;
      }
      this.handerRightMenu(item, true);
    },
    // 处理右边页面菜单模块的数据
    handerRightMenu(item, talg) {
      let v = this;
      if (talg) {
        v.selectPageRoleList = [];
      }
      v.panelList = [];
      let menuKey_arr = [];
      let menu_arr = [];
      if (item) {
        if (item.currentIndex === v.currentIndex) {
          if (item.menuKey) {
            menuKey_arr.push(item);
          } else {
            function rightMenu(menu) {
              menu.forEach((ele) => {
                if (!ele.menuHide) {
                  if (ele.children && ele.children.length > 0) {
                    rightMenu(ele.children);
                    menu_arr.push(ele);
                  } else {
                    menu_arr.push(ele);
                  }
                }
              });
            }

            if (item.children && item.children.length > 0) {
              rightMenu(item.children);
            }
          }
          [...menu_arr, ...menuKey_arr].map((item) => {
            if (item.menuKey) {
              item.pageRoleTalg = false;
              item.selectOperIdList = [];
              v.panelList.push(item);
            }
          });
          if (v.panelList.length > 0) {
            v.getPageRoleValue();
            v.selectPageRoleList = JSON.parse(JSON.stringify(v.originalSelectRoleList));
            this.handerRightPages(v.selectPageRoleList);
          }
        }
      }
    },
    // 获取对应页面下的权限值
    getPageRoleValue() {
      if (this.roleAllList.length > 0) {
        this.roleAllList.map((item) => {
          this.panelList.map((ele) => {
            if (ele.menuKey === item.menuId) {
              let data = item.data;
              ele['pageRoleList'] = data;
              ele['operIdList'] = [];
              ele['selectOperIdList'] = [];
              if (data) {
                let new_arr = [];
                data.map((talg) => {
                  if (talg.childrens && talg.childrens.length > 0) {
                    new_arr.push(talg.operId);
                    talg.childrens.forEach((item_1) => {
                      new_arr.push(item_1.operId);
                    });
                  } else {
                    new_arr.push(talg.operId);
                  }
                });
                if (new_arr) {
                  ele['operIdList'] = new_arr;
                }
              }
            }
          });
        });
      }
    },
    // 全选左边菜单模块下的所有页面权限
    cahngeAllMenuRole(value) {
      let v = this;
      v.moduleList.map((item, index) => {
        v.switchMenu(item, index, 'all');
        v.cahngeMenuRoleAll(value, item);
      });
      setTimeout(() => {
        v.moduleList.map((ele, idx) => {
          if (idx === v.originalIndex) {
            v.switchMenu(ele, idx);
          }
        });
        v.currentIndex = v.originalIndex;
      }, 500);
    },
    // 全选左边对应模块下的所有页面权限
    cahngeMenuRoleAll(value, data) {
      let v = this;
      let operIdList = [];
      if (v.panelList.length > 0 && data.currentIndex === v.currentIndex) {
        v.panelList.map((item) => {
          item.pageRoleTalg = value;
          if (item.operIdList) {
            operIdList.push(...item.operIdList);
          }
        });
        if (value) {
          v.selectPageRoleList = [...new Set([...v.selectPageRoleList, ...operIdList])];
        } else {
          if (operIdList) {
            for (let i = v.selectPageRoleList.length - 1; i >= 0; i--) {
              if (operIdList.includes(v.selectPageRoleList[i])) {
                v.selectPageRoleList.splice(i, 1);
              }
            }
          }
        }
        v.originalSelectRoleList = JSON.parse(JSON.stringify(v.selectPageRoleList));
        v.handerRightPages(v.selectPageRoleList);
      }
    },
    // 全选指定页面下的所有权限
    changePageRoleAll(value, data) {
      let v = this;
      data.pageRoleTalg = !data.pageRoleTalg;
      if (data.pageRoleTalg) {
        v.selectPageRoleList = [...new Set([...v.selectPageRoleList, ...data.operIdList])];
      } else {
        for (let i = v.selectPageRoleList.length - 1; i >= 0; i--) {
          if (data.operIdList) {
            if (data.operIdList.includes(v.selectPageRoleList[i])) {
              v.selectPageRoleList.splice(i, 1);
            }
          }
        }
      }
      v.originalSelectRoleList = JSON.parse(JSON.stringify(v.selectPageRoleList));
      v.handerRightPages(v.selectPageRoleList);
    },
    // 处理当取消选中详情权限时，清空详情权限下的子权限的值
    selectRoleList(value) {
      let v = this;
      if (value) {
        let new_arr = [];
        v.panelList.map((talg) => {
          if (talg.pageRoleList) {
            talg.pageRoleList.map((item) => {
              if (!value.includes(item.operId)) {
                if (item.childrens && item.childrens.length > 0) {
                  item.childrens.map((ele) => {
                    new_arr.push(ele.operId);
                  });
                }
              }
            });
          }
        });
        if (new_arr) {
          for (let i = 0; i < value.length - 1; i++) {
            for (let j = 0; j < new_arr.length; j++) {
              if (value[i] === new_arr[j]) {
                value.splice(i, 1);
              }
            }
          }
        }
        v.originalSelectRoleList = JSON.parse(JSON.stringify(value));
        v.handerRightPages(value);
      }
    },
    // 处理右边页面权限全选按钮是否选中的状态
    handerRightPages(data) {
      let v = this;
      let new_selectOperIdList = [];
      let new_operIdList = [];
      if (v.panelList && v.panelList.length > 0) {
        v.panelList.map((item) => {
          item['selectOperIdList'] = [];
          if (item.operIdList) {
            item.operIdList.map((ele) => {
              if (data.includes(ele)) {
                item['selectOperIdList'].push(ele);
              }
            });
          }
          if (item['selectOperIdList'] && item.operIdList) {
            if (item['selectOperIdList'].length === item.operIdList.length) {
              item.indeterminatePages = false;
              item.pageRoleTalg = true;
            } else {
              if (item['selectOperIdList'].length > 0) {
                item.indeterminatePages = true;
                item.pageRoleTalg = false;
              } else {
                item.indeterminatePages = false;
                item.pageRoleTalg = false;
              }
            }
            new_selectOperIdList.push(...item.selectOperIdList);
            new_operIdList.push(...item.operIdList);
          }
        });
      }
      if (v.moduleList.length > 0) {
        if (new_selectOperIdList.length === new_operIdList.length) {
          if (v.moduleList[v.currentIndex]['indeterminateMenu']) {
            v.moduleList[v.currentIndex]['indeterminateMenu'] = false;
          }
          v.$set(v.moduleList[v.currentIndex], 'modularTalg', true);
        } else {
          if (new_selectOperIdList.length > 0) {
            v.moduleList[v.currentIndex]['indeterminateMenu'] = true;
            v.$set(v.moduleList[v.currentIndex], 'modularTalg', false);
          } else {
            if (v.moduleList[v.currentIndex]['indeterminateMenu']) {
              v.moduleList[v.currentIndex]['indeterminateMenu'] = null;
            }
            v.$set(v.moduleList[v.currentIndex], 'modularTalg', false);
          }
        }
      }
    },
  },
  watch: {
    // 监听当前弹窗的状态类型
    roleType: {
      handler(value) {
        let v = this;
        if (value === 'add') {
          v.roleTitle = alias2f085dd487c1465383484787b29fef2c.t('key1004014');
        } else if (value === 'edit') {
          v.roleTitle = alias2f085dd487c1465383484787b29fef2c.t('key1004016');
        }
      }
    },
    moduleList: {
      handler(data) {
        if (data.length > 0) {
          let talg = data.every((item) => {
            return item.modularTalg
          });
          if (talg) {
            this.modularAllTalg = true;
            this.indeterminateAllMenu = false;
          } else {
            this.modularAllTalg = false;
            this.indeterminateAllMenu = true;
            let modularTalgs = data.every((item) => {
              return item.modularTalg === false
            });
            if (modularTalgs) {
              this.indeterminateAllMenu = null;
            }
          }
        } else {
          this.modularAllTalg = false;
          this.indeterminateAllMenu = null;
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.roleStyle {
  .role_box {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .left_role_box {
      width: 24%;
      border: 1px solid #d7dde4;
      box-sizing: border-box;

      .title_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        line-height: 40px;
        padding: 0 6px 0 15px;
        background-color: #f7f7f7;

        .title {
          color: #333;
          font-size: 17px;
          font-weight: bold;
        }
      }

      .left_item {
        display: flex;
        justify-content: space-between;
        height: 43px;
        line-height: 43px;
        padding: 0 6px 0 15px;
        border-top: 1px solid #d7dde4;
        cursor: pointer;

        .item_title {
          font-size: 14px;
          color: #666;
        }

        .item_title_active {
          color: #39f;
        }
      }
    }

    .right_role_box {
      width: 74%;
      min-height: 400px;
      max-height: 600px;
      overflow-y: auto;

      /deep/ .ivu-collapse-header {
        display: flex;
        align-items: center;
        position: relative;
      }

      .right_role_title {
        color: #666;
        font-size: 14px;
        font-weight: bold;
      }

      .right_checkbox {
        position: absolute;
        right: 10px;
      }

      .page_role_box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        font-size: 13px;


        .bigSquare {
          background-color: #e2e2e2;
          border-radius: 10px;
          width: 100%;
          padding-bottom: 11px;
          margin-top: 11px;
          display: flex;
          flex-wrap: wrap;
          padding-right: 14px;
          box-sizing: border-box;
        }
      }

      .roleItem {
        padding: 4px 6px;
        border: 1px solid #d7dde4;
        border-radius: 4px;
        background-color: #f7f7f7;
        margin-left: 14px;
        margin-top: 11px;
        max-width: 160px;

        /deep/ .ivu-checkbox-group-item {
          display: flex;
          align-items: center;

          .page_role_item_title {
            display: block;
            margin-left: 6px;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-wrap: break-word;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
