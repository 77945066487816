<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col class="employee_roles" :xxl="oneItemCol" :xl="oneItemCol" :lg="oneItemCol" :md="oneItemCol" v-if="roleList">
            <Button-group>
              <template v-for="(item, index) in roleList">
                <template v-if="index < 5">
                  <Button v-if="item.selected" type="primary" @click="roleControl(index)" :key="index">{{ item.title }}</Button>
                  <div class="pseudoButton" v-if="!item.selected" @click="roleControl(index)" :key="index">
                    <span class="title">{{ item.title }}</span>
                    <Icon
                      type="md-create"
                      v-if="getPermission('role_get') && roleControlStatus"
                      class="roleEdit"
                      @click="editRole(item.roleId, item.merchantId, item.title)"
                    />
                    <Icon
                      type="md-close"
                      v-if="getPermission('role_delete') && roleControlStatus"
                      class="roleDel"
                      @click="delRole(item.roleId)"
                    />
                  </div>
                </template>
              </template>
              <template v-if="roleList.length > 5">
                <Dropdown style="margin-left: 10px" class="select-dropdown-menu-styles">
                  <Button :type="moreTitle !== $t('key1004000') ? 'primary' : 'default'">
                    {{ moreTitle }}
                    <Icon type="md-arrow-dropdown"/>
                  </Button>
                  <template #list>
                    <Dropdown-menu>
                      <Dropdown-item v-for="(item, index) in roleList.slice(5)" :key="item.id">
                        <template>
                          <span @click="roleControl(index, 2, roleList.slice(5))">{{ item.title }}</span>
                          <Icon
                            type="md-create"
                            v-if="getPermission('role_get') && roleControlStatus"
                            class="roleEdit"
                            @click="editRole(item.roleId, item.merchantId, item.title)"
                          />
                          <Icon
                            type="md-close"
                            v-if="getPermission('role_delete') && roleControlStatus"
                            class="roleDel"
                            @click="delRole(item.roleId)"
                          />
                        </template>
                      </Dropdown-item>
                    </Dropdown-menu>
                  </template>
                </Dropdown>
              </template>
            </Button-group>
            <div class="manageRole" v-if="!roleControlStatus" @click="manageRole">
              <span class="icon iconfont">&#xe634;</span> {{ $t('key1004003') }}
            </div>
            <div class="manageOperate" v-else-if="roleControlStatus">
              <span v-if="getPermission('role_insert')" class="icon iconfont addRole" @click="addRole">&#xe671; {{ $t('key1001677') }}</span>
              <span class="icon iconfont returnRole" @click="returnRole">&#xe62f; {{ $t('key1000570') }}</span>
            </div>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1001980')">
              <Input :placeholder="$t('key1004001')" clearable v-model.trim="pageParams.searchValue" @on-enter="search"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1004002')">
              <local-tabButton :tabButtonList="enableList" @selectStatus="enableBtn"></local-tabButton>
            </FormItem>
          </Col>
          <Col style="width:170px;" push="1">
            <Button @click="reset" v-once icon="md-refresh" class="mr10">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" v-if="getPermission('ymsSupplierUserInfo_query')" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <span>{{ $t('key1002168') }}</span>
        <Button-group>
          <template v-for="(item, index) in buttonGroupModel">
            <Button
              v-if="item.selected" type="primary" @click="modifyTheSort(index, item.status)" :key="index">
              {{ item.title }}
              <Icon type="md-arrow-round-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-round-down" v-if="!item.status && item.selected"></Icon>
            </Button>
            <Button
              v-if="!item.selected" @click="modifyTheSort(index, item.status)" :key="index">
              {{ item.title }}
              <Icon type="md-arrow-round-up" v-if="item.status && item.selected"></Icon>
              <Icon type="md-arrow-round-down" v-if="!item.status && item.selected"></Icon>
            </Button>
          </template>
        </Button-group>
      </div>
      <Button
        class="mr15"
        v-if="getPermission('ymsSupplierUserInfo_insert')" type="primary" icon="md-add"
        @click="showAddNewEmployeeModal">{{ $t('key1004004') }}
      </Button>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        highlight-row
        border
        max-height="490"
        :loading="tableLoading"
        :columns="columns"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray"
        :current="curPage">
      </Page>
    </div>
    <!--新增、编辑、查看员工的弹窗-->
    <staffModal
      ref="staffModalbox"
      :staffType="staffType"
      :roleList="roleList"
      @saveSuccess="saveSuccess">
    </staffModal>
    <!--新增、编辑、角色的弹窗-->
    <roleModal
      ref="roleBox"
      :roleName="roleName"
      :roleType="roleType"
      @saveSuccess="saveSuccess">
    </roleModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import staffModal from "./staffModal";
import roleModal from "./roleModal";

export default {
  name: "management",
  mixins: [Mixin],
  data() {
    var self = this;
    return {
      staffType: 'add',
      roleType: 'add',
      buttonGroupModel: [
        {
          type: 'userName',
          selected: true,
          status: true, // true up false down
          title: alias2f085dd487c1465383484787b29fef2c.t('key1004005') // 按姓名
        },
        {
          type: 'email',
          selected: false,
          status: true, // true up false down
          title: alias2f085dd487c1465383484787b29fef2c.t('key1004006') // '按邮箱'
        }
      ],
      roleControlStatus: false,
      roleName: '',
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        status: null,
        roleId: null,
        orderBy: 'userName',
        upDown: 'up',
        searchValue: null
      },
      pageParamsStatus: false,
      total: 0,
      curPage: 1,
      moreTitle: alias2f085dd487c1465383484787b29fef2c.t('key1004000'),
      enableList: [
        {
          selected: true,
          value: null,
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000095')
        },
        {
          selected: false,
          value: '1',
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000497')
        },
        {
          selected: false,
          value: '0',
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000498')
        }
      ],
      roleList: [],
      columns: [
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001700'),
          key: 'email',
          align: 'center'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001691'),
          key: 'userName',
          align: 'center'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1001992'),
          key: 'mobile',
          align: 'center'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1004007'),
          align: 'center',
          sortable: true,
          key: 'lastLoginDate'
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000496'),
          key: 'status',
          align: 'center',
          render: (h, params) => {
            return params.row.supplierStatus === '0' ? h('span', {
              class: 'stopStatus'
            }, alias2f085dd487c1465383484787b29fef2c.t('key1000498')) : h('span', {
              class: 'openStatus'
            }, alias2f085dd487c1465383484787b29fef2c.t('key1000497'));
          }
        }, {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000278'),
          key: 'action',
          align: 'center',
          width: 200,
          fixed: 'right',
          render: (h, params) => {
            let supplierStatus = params.row.supplierStatus;
            if (params.row !== undefined) {
              return h('optsButtonList', {
                props: {
                  operationList: [
                    {
                      text: alias2f085dd487c1465383484787b29fef2c.t('key1001508'),
                      show: self.getPermission('ymsSupplierUserInfo_query'),
                      clickFn: () => {
                        self.viewEmployees(params.row.userId);
                      }
                    },
                    {
                      text: alias2f085dd487c1465383484787b29fef2c.t('key1000631'),
                      show: self.getPermission('ymsSupplierUserInfo_update'),
                      clickFn: () => {
                        self.editorialStaff(params.row.userId);
                      }
                    },
                    {
                      text: alias2f085dd487c1465383484787b29fef2c.t('key1000497'),
                      show: self.getPermission('ymsSupplierUserInfo_enable') && supplierStatus === '0' && params.row.isAdmin !== 1,
                      clickFn: () => {
                        self.enableStatus(params.row.userId);
                      }
                    },
                    {
                      text: alias2f085dd487c1465383484787b29fef2c.t('key1000498'),
                      show: self.getPermission('ymsSupplierUserInfo_disable') && supplierStatus !== '0' && params.row.isAdmin !== 1,
                      clickFn: () => {
                        self.disableStatus(params.row.userId);
                      }
                    }
                  ]
                },
                on: {
                  setWidth(value) {
                    if (value) {
                      self.optionBtnWidth.push(value);
                      params.column.width = Math.max(...self.optionBtnWidth);
                      self.$forceUpdate();
                    }
                  }
                }
              });
            }
          }
        }
      ],
      tableData: [],
    };
  },
  created() {
    let v = this;
    v.getPermissionCallback('supplier_accountManage_management').then(() => {
      v.getRoleList(); // 获取角色的数据
      v.getUserList();// 获取用户列表
    });
  },
  methods: {
    // 获取所有角色列表的数据
    getRoleList() {
      let v = this;
      v.axios.post(api.post_role_query).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          let pos = [];
          pos[0] = {
            selected: true,
            roleId: '',
            merchantId: '',
            title: alias2f085dd487c1465383484787b29fef2c.t('key1000095')
          };
          data.forEach((n, i) => {
            i = i + 1;
            pos[i] = {
              selected: false,
              roleId: n.roleId,
              merchantId: n.merchantId,
              title: n.roleName
            };
          });
          v.roleList = pos;
        }
      });
    },
    // 启用按钮
    enableStatus(params) {
      let v = this;
      v.axios.put(api.put_ymsSupplierUserInfo_enable + '?userId=' + params).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1001509'));
          v.pageParamsStatus = true;
        } else {
          v.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1005371'));
        }
      });
    },
    // 停用按钮
    disableStatus(params) {
      let v = this;
      v.$Modal.confirm({
        title: alias2f085dd487c1465383484787b29fef2c.t('key1002317'),
        onOk: () => {
          v.axios.put(api.put_ymsSupplierUserInfo_disable + '?userId=' + params).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000130'));
              v.pageParamsStatus = true;
            } else {
              v.$Message.error(alias2f085dd487c1465383484787b29fef2c.t('key1005372'));
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 头部筛选：启用、停用按钮的切换
    enableBtn(index, value) {
      let v = this;
      v.pageParams.status = value;
      v.enableList.forEach((n, i) => {
        n.selected = i === index;
      });
    },
    // 角色按钮的切换
    roleControl(index, type, data) {
      let v = this;
      if (index === 0) {
        v.pageParams.roleId = null;
      }
      if (!v.roleControlStatus) {
        v.moreTitle = alias2f085dd487c1465383484787b29fef2c.t('key1004000');
        v.roleList.forEach((n, i) => {
          n.selected = false;
          if (type === 2) {
            data.map((item, idx) => {
              if (idx === index) {
                v.moreTitle = item.title;
                item.selected = true;
                v.pageParams.roleId = item.roleId;
              } else {
                item.selected = false;
              }
            });
          } else {
            if (i === index) {
              n.selected = true;
              v.pageParams.roleId = n.roleId;
            } else {
              n.selected = false;
            }
          }
        });
      }
    },
    // 查询按钮
    search() {
      let v = this;
      v.curPage = 1;
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 管理员工、角色按钮
    manageRole() {
      let v = this;
      v.roleControl(0);
      v.roleControlStatus = true;
    },
    // 退出员工、角色编辑状态
    returnRole() {
      let v = this;
      v.roleControlStatus = false;
    },
    // 重置按钮
    reset() {
      let v = this;
      v.pageParams.searchValue = '';
      v.enableBtn(0, null);
      v.roleControl(0);
      v.pageParamsStatus = true;
    },
    // 新增、编辑员工成功后，更新列表数据
    saveSuccess() {
      let v = this;
      v.pageParamsStatus = true;
      v.roleControlStatus = false;
      v.getRoleList();
    },
    // 添加新角色
    addRole() {
      let v = this;
      v.roleName = '';
      v.roleType = 'add';
      v.$refs.roleBox.getRoleData(null);
    },
    // 编辑角色
    editRole(roleId, merchantId, title) {
      let v = this;
      v.roleName = title;
      v.roleType = 'edit';
      v.$refs.roleBox.getRoleData(roleId);
    },
    // 删除角色
    delRole(id) {
      let v = this;
      v.$Modal.confirm({
        title: alias2f085dd487c1465383484787b29fef2c.t('key1004008'),
        onOk: () => {
          v.axios.delete(api.delete_role + id, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000581'));
              v.getRoleList();
            }
          });
        },
        onCancel: () => {
        }
      });
    },
    // 获取员工列表
    getUserList() {
      let v = this;
      v.tableData = [];
      v.total = 0;
      if (v.getPermission('ymsSupplierUserInfo_query')) {
        v.tableLoading = true;
        v.axios.post(api.post_ymsSupplierUserInfo_query, JSON.stringify(v.pageParams)).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data.list !== null) {
              data.list.forEach((n) => {
                n.lastLoginDate = n.lastLoginTime ? v.$uDate.dealTime(n.lastLoginTime, 'fulltime') : '';
              });
            }
            v.tableData = data.list || [];
            v.total = data.total;
            v.$nextTick(() => {
              v.tableLoading = false;
            });
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 添加新员工
    showAddNewEmployeeModal() {
      let v = this;
      v.staffType = 'add';
      v.$refs.staffModalbox.newEmployeeModal = true;
    },
    // 查看员工信息
    viewEmployees(userId) {
      this.staffType = 'look';
      this.$refs.staffModalbox.getUserInfo('look', userId);
    },
    // 编辑员工信息
    editorialStaff(userId) {
      this.staffType = 'edit';
      this.$refs.staffModalbox.getUserInfo('edit', userId);
    }
  },
  watch: {
    pageParamsStatus(n) {
      let v = this;
      if (n) {
        v.getUserList();
        v.pageParamsStatus = false;
      }
    },
  },
  components: {
    staffModal,
    roleModal
  }
};
</script>

<style lang="less" scoped>
.filter_box {
  .employee_roles {
    display: flex;
    margin-bottom: 24px;
    margin-left: 50px;

    .manageRole {
      color: #39f;
      cursor: pointer;
      height: 32px;
      line-height: 32px;
      margin-left: 20px;
    }

    .manageOperate {
      display: flex;
      position: relative;
      top: 5px;
      margin-left: 20px;

      .addRole {
        cursor: pointer;
        color: #39f;
        font-size: 14px;
      }

      .returnRole {
        cursor: pointer;
        color: #d13131;
        margin-left: 15px;
        font-size: 14px;
      }

    }

    .ivu-btn-group {
      display: flex;
    }

    .title {
      color: #666;
      font-size: 14px;
    }

    .roleEdit {
      font-size: 15px;
      margin: 0 6px;
      color: #39F;
      cursor: pointer;
    }

    .roleDel {
      font-size: 17px;
      color: #cf2a28;
      cursor: pointer;
    }

    .pseudoButton {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      background-image: none;
      white-space: nowrap;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 0 15px;
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      border-radius: 4px;
      transition: color .2s linear, background-color .2s linear, border .2s linear;
      color: #495060;
      background-color: #f7f7f7;
      border: 1px solid #dddee1;
      box-sizing: border-box;
    }

    .pseudoButton:not(:first-child):not(:last-child) {
      border-radius: 0
    }

    .ivu-btn-group:not(.ivu-btn-group-vertical) > .pseudoButton:last-child:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    /deep/ .select-dropdown-menu-styles {
      .ivu-select-dropdown {
        max-height: 350px !important;
        overflow-y: auto;
      }
    }
  }
}
</style>
